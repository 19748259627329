import * as dompack from "dompack";

dompack.onDomReady(() => {
  let page = document.querySelector("#my-page > main");
  let floater = document.querySelector(".w-floater");
  let minScroll = document
    .querySelector("html")
    .classList.contains("first-widget-slideshow")
    ? window.getComputedStyle(
        document.querySelectorAll(".widget--slideshow")[0]
      ).height
    : page.scrollHeight * 0.2;

  const checkScroll = () => {
    if (window.scrollY > parseInt(minScroll)) {
      floater.style.animation = "slideInFromSide 0.6s ease-out";
      floater.style.transform = "translateX(0)";
      visible = true;
    }
  };

  checkScroll();

  document.addEventListener("scroll", (e) => {
    checkScroll();
  });
});
