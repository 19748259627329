// don't load SCSS here, only load SCSS through other SCSS files to prevent duplicates

import './button/button.scss';
import './imagefullwidth/imagefullwidth.scss';
import './overview/overview.scss';
// import './photos/photos';

// has JS (which loads SCSS)
import './slideshow/slideshow';
// import './slider/slider';
import './textimage/textimage';
import './textvideo/textvideo';
import './twocols/twocols';
import './videoslider/videoslider';
import './photoalbum/photoalbum';

import './onetextcols/onetextcols';
import './expandabletabs/expandabletabs';
import './textimageslider/textimageslider';
import './verticaltabs/verticaltabs';
// import './photoalbum/photoalbum';
import './ctaslider/ctaslider';
import './thirdpartyframe/thirdpartyframe';
import './logoslider/logoslider';
import './typewriter/typewriter';
import './history/history';
import './ctas/ctas';
import './socials/socials';
import './row/row';
import './eventoverview/eventoverview';
