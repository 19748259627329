import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
import "@mod-mso/webdesigns/mso/mso.scss";

import "./zennalaser.scss";
import "./components/floater/floater";

dompack.onDomReady(() => {
  new mso.MSOSite();
});
